html {
  scroll-padding-top: 100px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.quill.red1d {
  border: solid 1px red;
}

.pt25 {
  padding-top: 25px;
}
.mt25 {
  margin-top: 25px !important;
}
.flaggy {
  position: absolute;
  right: 15px;
  top: 30px;
}
.ql-snow a {
  color: blue !important;
}
.ql-editor {
  height: 100px;
}
.msg .quill {
  height: 250px;
}
.tablistNone .MuiTabs-root {
  display: none;
}
.tablistNone {
  padding: 0px !important;
}
.tablistNone .MuiAccordionDetails-root {
  /*width: 550px;*/
  /*padding: 0px !important;*/
}
.tablistNone .MuiPaper-elevation1 {
  box-shadow: unset;
}
.makeStyles-headerLogo-26 {
  max-height: 50px !important;
}
.rdt_TableHead > div > div:nth-child(5),
.rdt_TableRow > div:nth-child(5) {
  min-width: 250px !important;
}
.rdt_TableRow {
  cursor: pointer;
}
.rdt_TableRow:hover {
  background-color: #1d40af82;
}
.rdt_Pagination {
  background-color: unset !important;
}
.tb_container,
.MuiTable-root {
  border: 1px solid #ffffff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.rdt_TableHead > div > div {
  font-size: 16px;
}
main {
  background: #dbd9d96b;
}
.MuiAutocomplete-clearIndicator {
  display: none !important;
}
.MuiDialog-paperFullScreen {
  border-radius: 30px;
}
/*.makeStyles-textField-74 {
  width: 200px;
  margin-left: 0px;
  margin-right: 30px;
}*/
th.MuiTableCell-root.MuiTableCell-head:nth-child(1) {
  color: #7b7b7b;
}
.rdt_Pagination {
  background-color: white !important;
  color: black !important;
}
.MuiPaper-elevation1 {
  box-shadow: unset !important;
}
